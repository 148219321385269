import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  logIn,
  setSelectedCompanyRutFromExternalLogin,
} from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { SENEGOCIA_AUTH } from '@fingo/lib/graphql';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';

const SenegociaClickController = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const purchaseOrderId = urlParams.get('purchase_order_id');
  const [loginSenegocia, { loading }] = useMutation(SENEGOCIA_AUTH, {
    variables: {
      token: urlParams.get('token'),
      isOrdering: urlParams.get('button') === 'button_ordering',
      loginFlag: urlParams.get('button'),
    },
    onCompleted: (_data) => {
      setSelectedCompanyRutFromExternalLogin({ actualCompanyRut: Number(urlParams.get('company_rut').split('-')[0]) });
      logIn(_data.senegociaAuth);
      const button = urlParams.get('button');
      if (button === 'button_ordering') {
        const searchString = purchaseOrderId ? `purchaseOrderId=${purchaseOrderId}` : '';
        history.replace(`/app/sales/ordering/available?${searchString}`);
      } else {
        history.replace('/app/sales/factoring');
      }
    },
    onError: () => {},
  });
  useEffect(() => {
    loginSenegocia();
  }, [loginSenegocia]);
  return (
    <Stack height="100vh" width="100%" alignItems="center" justifyContent="center" spacing={2}>
      {(loading) && (
        <FingoCircularProgressLoader />
      )}
      {(!loading) && (
        <>
          <Typography variant="h3" color="primary">
            ¡Tu sesión en senegocia ha expirado!
          </Typography>
          <Typography variant="h5">
            Intente refrescar su sesión en Senegocia e intente nuevamente,
            o aprete <Link to="/">aquí</Link> para hacer login con sus credenciales de Fingo
            o <Link to="/login?loginType=signup">aquí</Link> para crear su cuenta en Fingo.
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default SenegociaClickController;
